  <!-- 
<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0">Вход Сотрудников</p>
          <div>
            <el-button type="success" size="medium" plain>{{
              $t("message.save")
            }}</el-button>
            <el-button type="warning" size="medium" plain>{{
              $t("message.close")
            }}</el-button>
          </div>
        </div>
      </div>
     

      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4">
          <el-form ref="form" :model="form">
            <el-row :gutter="20">
             
              <el-col :span="12">
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2"> Номер машины </span>

                  <el-select
                  :class="mode ? 'input__day' : 'input__night'"
                    size="medium"
                    class="w-100"
                    v-model="form.region1"
                    :placeholder="$t('message.department_')"
                    filterable
                  >
                    <el-option label="Zone one" value="shanghai"></el-option>
                    <el-option label="Zone two" value="beijing"></el-option>
                  </el-select>
                </div>
              </el-col>
            

              <el-col :span="12">
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2"> Шофёр </span>

                  <el-select
                  :class="mode ? 'input__day' : 'input__night'"
                    size="medium"
                    class="w-100"
                    v-model="form.region1"
                    :placeholder="$t('message.department_')"
                    filterable
                  >
                    <el-option label="Zone one" value="shanghai"></el-option>
                    <el-option label="Zone two" value="beijing"></el-option>
                  </el-select>
                </div>
              </el-col>
              

              <el-col :span="12">
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2"> Куда </span>

                  <el-select
                  :class="mode ? 'input__day' : 'input__night'"
                    size="medium"
                    class="w-100"
                    v-model="form.region1"
                    :placeholder="$t('message.department_')"
                    filterable
                  >
                    <el-option label="Zone one" value="shanghai"></el-option>
                    <el-option label="Zone two" value="beijing"></el-option>
                  </el-select>
                </div>
              </el-col>
              
              <el-col :span="12">
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    Планированый приход
                  </span>
                  <el-input
                    placeholder="Please input"
                    size="medium"
                    v-model="form.input"
                  ></el-input>
                </div>
              </el-col>

              <el-col :span="12">
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    Фактический приход
                  </span>
                  <el-input
                    placeholder="Please input"
                    size="medium"
                    v-model="form.input"
                  ></el-input>
                </div>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
    end app-modal__body 
    </div>
  </div>
</template>

<script>
import selectTransport from "@/components/filters/inventory/select-transport";
import selectStaff from "@/components/filters/inventory/select-staff";
export default {
  data() {
    return {
      form: {},
      region1: "",
      region2: "",
      region3: "",
      checked: "",
    };
  },
  components: {},
  mounted() {},
};
</script>
-->
<template >
  <div>
    <div class="app-modal__box"  :class="mode ? '' : 'performance__night'">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0" :class="mode ? 'text__day2' : 'text__night2'">
            {{$t("message.employee_kpp")}}
          </p>
          <div>
            <crm-store-update-close
              :permission="$options.name"
              :button_type="'store'"
              :loading="loadingButton"
              @c-submit="submit(true)"
              @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->
      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4" :class="mode ? 'table__myday' : 'table__mynight'">
          <el-form ref="form" status-icon :model="form" :rules="rules">
            <el-row :gutter="20">
              <el-col :span="4">
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                  {{ $t("message.type") }}
                </span>
                <el-form-item prop="type">
                  <el-select
                  :class="mode ? 'input__day' : 'input__night'"
                    v-model="form.type"
                    :placeholder="$t('message.type')"
                    filterable
                    :size="'small'"
                    class="d-block"                   
                  >
                    <el-option
                      v-for="(type, index) in types"
                      :key="'type-' + index"
                      :label="type.label"
                      :value="type.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                  {{ $t("message.transport") }}
                </span>
                <el-form-item prop="transport_id" class="label_mini">
                  <select-transport
                    :id="form.transport_id"
                    v-model="form.transport_id"
                    :placeholder="$t('message.transport')"
                    @staffId="setStaffId"
                  ></select-transport>
                </el-form-item>
                 
              </el-col>
              <el-col :span="2">
                  <div class="pulus " style="height: 33px;  margin-top: 28px;" @click="dialogCreateModel = true" :class="mode ? 'pulus__myday' : 'pulus__mynight'">
                    <i class="el-icon-plus"></i>
                  </div>
                </el-col>
              <el-col :span="10">
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                  {{ $t("message.car_driver") }}
                </span>
                <el-form-item prop="driver" class="label_mini">
                    <crm-input
                    :class="mode ? 'input__day' : 'input__night'"
                      :inputValue="form.driver"
                      v-model="form.driver"
                      :placeholder="$t('message.car_driver')"
                    ></crm-input>
                </el-form-item>
              </el-col>
              <el-col :span="4" v-if="form.type == 'entrance'">
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block">
                  {{ $t("message.expected_emp") }}
                </span>
                <el-form-item prop="expected_employee" class="label_mini">
                  <crm-input
                  :class="mode ? 'input__day' : 'input__night'"
                    type="number"
                    :inputValue="form.expected_employee"
                    v-model="form.expected_employee"
                    :placeholder="$t('message.chel')"
                  ></crm-input>
                </el-form-item>
              </el-col>
              <el-col :span="4" v-if="form.type == 'exit'">
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block">
                  {{ $t("message.exit") }}
                </span>
                <el-form-item prop="left_employee" class="label_mini">
                  <crm-input
                  :class="mode ? 'input__day' : 'input__night'"
                    type="number"
                    :inputValue="form.left_employee"
                    v-model="form.left_employee"
                    :placeholder="$t('message.chel')"
                  ></crm-input>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block">
                  {{ $t("message.left_date") }}
                </span>
                <el-form-item prop="left_date" class="label_mini">
                  <crm-date-picker
                    :date="form.left_date"
                    :placeholder="$t('message.left_date')"
                    v-model="form.left_date"
                    :class="mode ? 'input__day' : 'input__night'"
                  >
                  </crm-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block">
                  {{ $t("message.left_time") }}
                </span>
                <el-form-item prop="left_time" class="label_mini">
                  <el-time-picker
                    value-format="HH:mm:ss"
                    format="HH:mm:ss"
                    size="small"
                    :placeholder="$t('message.left_time')"
                    v-model="form.left_time"
                    style="width: 100%"
                    :class="mode ? 'input__day' : 'input__night'"
                  ></el-time-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                  {{ $t("message.district") }}
                </span>
                <el-form-item prop="district">
                  <crm-input
                     :class="mode ? 'input__day' : 'input__night'"
                      :inputValue="form.district"
                      v-model="form.district"
                      :size="'small'"
                      :placeholder="$t('message.district')"
                    ></crm-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                  <select-company
                    :id="form.company_id"
                    v-model="form.company_id"
                  ></select-company>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>

    <el-dialog
      :visible.sync="dialogCreateModel"
      width="80%"
      :append-to-body="true"
      center
      ref="dialogCreateModel"
      :class="mode ? 'dialog__myday' : 'dialog__mynight'"
    >
      <create-service
       
        ref="dialogCreateModelChild"
        dialog="dialogCreateModel"
      ></create-service>
    </el-dialog>

    <el-dialog
        :visible.sync="regionCreateModel"
        width="60%"
        :append-to-body="true"
        center
        ref="regionCreateModel"
        :class="mode ? 'dialog__myday' : 'dialog__mynight'"
      >
        <create-region
          @closeRegionModal="closeRegionModal"
          ref="regionCreateModelChild"
          dialog="regionCreateModel"
        ></create-region>
      </el-dialog>

      <el-dialog
        :visible.sync="districtCreateModel"
        width="60%"
        :append-to-body="true"
        center
        ref="districtCreateModel"
        :class="mode ? 'dialog__myday' : 'dialog__mynight'"
      >
        <create-district
          @closeDistrictModal="closeDistrictModal"
          ref="districtCreateModelChild"
          dialog="districtCreateModel"
        ></create-district>
      </el-dialog>
  </div>
</template>
<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import form from "@/utils/mixins/form";
import selectTransport from "@/components/filters/inventory/select-transport";
import selectCompany from "@/components/filters/inventory/select-or-hidden-company";
import selectStaff from "@/components/filters/inventory/select-staff";
import createService from "./create_service.vue";
import createRegion from "./create-region.vue";
import createDistrict from "./create-district.vue";
import drawer from "@/utils/mixins/drawer";
import { i18n } from "@/utils/i18n";
export default {
  mixins: [form, drawer],
  name: "OperationController",
  components: { selectTransport, selectStaff, selectCompany, createService, createRegion, createDistrict },
  data() {
    return {
       dialogCreateModel: false,
       regionCreateModel:false,
       districtCreateModel:false,

      types: [
        {
          label: i18n.t("message.entrance"),
          value: "entrance",
        },
        {
          label: i18n.t("message.exit"),
          value: "exit",
        },
      ],
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      rules: "employeeKpp/rules",
      model: "employeeKpp/model",
      columns: "employeeKpp/columns",
      mode: "MODE"
    }),
  },
  methods: {
    ...mapActions({
      save: "employeeKpp/store",
      empty: "employeeKpp/empty",
    }),
    afterOpen() {
      this.form.left_date = moment().format("DD.MM.YYYY");
      this.form.left_time = moment().format("HH:mm:ss");
    },
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.save(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$alert(res);
              if (res.status == 201) {
                this.parent().listChanged();
                this.close();
                this.empty();
              }
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
    setStaffId(staff_id) {
      this.form.staff_id = staff_id;
    },
    closeRegionModal(val) {
      this.regionCreateModel = val;
    },
    closeDistrictModal(val) {
      this.districtCreateModel = val;
    },
  },
};
</script>

