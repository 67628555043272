<template>
  <div class="p-pegas">
    <div
      class="card-table"
      :class="mode ? 'card__tableday' : 'card__tablenight'"
    >
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="caz-blocks-sarcho-title">
              <div
                class="content-title d-flex align-center mr-2"
                :class="mode ? 'content__titleday' : 'content__titlenight'"
              >
                {{ $t("message.employee_kpp") }}
              </div>
              <div class="block-sarche">
                <div class="header__search">
                  <crm-input
                    :size="'small'"
                    :className="'w100'"
                    :class="mode ? 'input__day' : 'input__night'"
                    v-model="filterForm.search"
                    :icon="'el-icon-search'"
                    class="mr-2"
                  ></crm-input>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="12" class="flex-style text-right">
            <div class="ml-2" style="float: right">
              <crm-create-and-column-settings
                :permission="$options.name"
                :class="mode ? 'button__settingsday' : 'button__settingsnight'"
                @c-create="drawerCreate = true"
                :columns="columns"
                @c-change="updateColumn"
                :v_can_create="'employee_kpps.create'"
              >
              </crm-create-and-column-settings>
            </div>

            <export-excel
              v_can="'employee_kpps.excel'"
              class="btn excel_btn"
              style="display:inline-flex"
              :data="list"
              @fetch="controlExcelData()"
              :fields="excel_fields"
              :worksheet="$t('message.employee_kpp')"
              :name="$t('message.employee_kpp')"
            >
              <el-button size="mini">
                <i class="el-icon-document-delete"></i>
                {{ $t("message.excel") }}
              </el-button>
            </export-excel>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->
      <div class="card-table-header table-crm-smart">
        <table
          class="table-my-code table-bordered"
          v-loading="loadingData"
          :class="mode ? 'table__myday' : 'table__mynight'"
        >
          <thead>
            <tr>
              <th class="w50p" v-if="columns.id.show">
                {{ columns.id.title }}
              </th>
              <th v-if="columns.type.show">
                {{ columns.type.title }}
              </th>
              <th v-if="columns.car_number.show">
                {{ columns.car_number.title }}
              </th>
              <th v-if="columns.car_model.show">
                {{ columns.car_model.title }}
              </th>
              <th v-if="columns.car_color.show">
                {{ columns.car_color.title }}
              </th>
              <th v-if="columns.driver.show">
                {{ columns.driver.title }}
              </th>

              <th v-if="columns.left_date_time.show">
                {{ columns.left_date_time.title }}
              </th>
              <!-- <th v-if="columns.left_time.show">
                {{ columns.left_time.title }}
              </th> -->
              <th v-if="columns.arrival_date_time.show">
                {{ columns.arrival_date_time.title }}
              </th>
              <!-- <th v-if="columns.arrival_time.show">
                {{ columns.arrival_time.title }}
              </th> -->
              <th v-if="columns.total_spent_time.show">
                {{ columns.total_spent_time.title }}
              </th>
              <th v-if="columns.expected_employee.show">
                {{ columns.expected_employee.title }}
              </th>
              <th v-if="columns.factual_employee.show">
                {{ columns.factual_employee.title }}
              </th>
              <th v-if="columns.left_employee.show">
                {{ columns.left_employee.title }}
              </th>
              <th v-if="columns.district.show">
                {{ columns.district.title }}
              </th>
              <th v-if="columns.created_at.show">
                {{ columns.created_at.title }}
              </th>
              <th v-if="columns.updated_at.show">
                {{ columns.updated_at.title }}
              </th>
              <th v-if="columns.settings.show">
                {{ columns.settings.title }}
              </th>
            </tr>
            <tr class="filter_sorche">
              <th v-if="columns.id.show">
                <el-input
                  clearable
                  size="mini"
                  v-model="filterForm.id"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.id.title"
                  class="id_input"
                ></el-input>
              </th>
              <th v-if="columns.type.show">
                <el-select
                  v-model="form.type"
                  :placeholder="$t('message.type')"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  filterable
                  :size="'mini'"
                  class="d-block"
                >
                  <el-option
                    v-for="(type, index) in types"
                    :key="'type-' + index"
                    :label="type.label"
                    :value="type.value"
                  ></el-option>
                </el-select>
              </th>
              <th v-if="columns.car_number.show">
                <crm-input
                  :inputValue="filterForm.car_number"
                  :placeholder="columns.car_number.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.car_number"
                  size="mini"
                ></crm-input>
              </th>
              <th v-if="columns.car_model.show">
                <select-guest-car-model
                  :size="'mini'"
                  :id="filterForm.guest_car_model_id"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.guest_car_model_id"
                ></select-guest-car-model>
              </th>
              <th v-if="columns.car_color.show">
                <crm-input
                  :inputValue="filterForm.car_color"
                  :placeholder="columns.car_color.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.car_color"
                  size="mini"
                ></crm-input>
              </th>
              <th v-if="columns.driver.show">
                <crm-input
                  :inputValue="filterForm.driver"
                  :placeholder="columns.driver.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.driver"
                  size="mini"
                ></crm-input>
              </th>
              <th v-if="columns.left_date_time.show">
                <crm-date-picker
                  :placeholder="columns.left_date_time.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.left_date"
                  size="mini"
                >
                </crm-date-picker>
              </th>
              <!-- <th v-if="columns.left_time.show">
                <el-time-picker
                  value-format="HH:mm:ss"
                  format="HH:mm:ss"
                  :placeholder="columns.left_time.title"
                  v-model="filterForm.left_time"
                  style="width: 100%"
                  size="mini"
                ></el-time-picker>
              </th> -->
              <th v-if="columns.arrival_date_time.show">
                <crm-date-picker
                  :placeholder="columns.arrival_date_time.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.arrival_date"
                  size="mini"
                >
                </crm-date-picker>
              </th>
              <!-- <th v-if="columns.arrival_time.show">
                <el-time-picker
                  value-format="HH:mm:ss"
                  format="HH:mm:ss"
                  :placeholder="columns.arrival_time.title"
                  v-model="filterForm.arrival_time"
                  style="width: 100%"
                  size="mini"
                ></el-time-picker>
              </th> -->
              <th v-if="columns.total_spent_time.show">
                <crm-input
                  :inputValue="filterForm.total_spent_time"
                  :placeholder="columns.total_spent_time.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.total_spent_time"
                  size="mini"
                ></crm-input>
              </th>
              <th v-if="columns.expected_employee.show">
                <crm-input
                  :inputValue="filterForm.expected_employee"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.expected_employee.title"
                  v-model="filterForm.expected_employee"
                  size="mini"
                ></crm-input>
              </th>
              <th v-if="columns.factual_employee.show">
                <crm-input
                  :inputValue="filterForm.factual_employee"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.factual_employee.title"
                  v-model="filterForm.factual_employee"
                  size="mini"
                ></crm-input>
              </th>
              <th v-if="columns.left_employee.show">
                <crm-input
                  :inputValue="filterForm.left_employee"
                  :placeholder="columns.left_employee.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.left_employee"
                  size="mini"
                ></crm-input>
              </th>
              <th v-if="columns.district.show">
                <crm-input
                  :inputValue="filterForm.district"
                  :placeholder="columns.district.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.district"
                  size="mini"
                ></crm-input>
              </th>

              <th v-if="columns.created_at.show">
                <crm-date-picker
                  :placeholder="columns.created_at.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.created_at"
                  size="mini"
                >
                </crm-date-picker>
              </th>

              <th v-if="columns.updated_at.show">
                <crm-date-picker
                  :placeholder="columns.updated_at.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.updated_at"
                  size="mini"
                >
                </crm-date-picker>
              </th>

              <th class="settinW" v-if="columns.settings.show"></th>
            </tr>
          </thead>

          <transition-group name="flip-list" tag="tbody">
            <tr v-for="emp in list" :key="emp.id" class="cursor-pointer">
              <td v-if="columns.id.show">{{ emp.id }}</td>
              <td v-if="columns.type.show">
                {{
                  emp.type == "exit"
                    ? $t("message.exit")
                    : $t("message.entrance")
                }}
              </td>
              <td v-if="columns.car_number.show">
                {{ emp.transport ? emp.transport.number : "" }}
              </td>
              <td v-if="columns.car_model.show">
                {{ emp.transport ? emp.transport.model.model : "" }}
              </td>
              <td v-if="columns.car_color.show">
                {{ emp.transport ? emp.transport.color : "" }}
              </td>
              <td v-if="columns.driver.show">
                {{ emp.driver }}
              </td>
              <td v-if="columns.left_date_time.show">
                {{ emp.left_date }}
                {{ emp.left_time }}
              </td>
              <!-- <td v-if="columns.left_time.show">
                {{ emp.left_time }}
              </td> -->
              <td v-if="columns.arrival_date_time.show">
                {{ emp.arrival_date }}
                <el-button
                  type="success"
                  size="mini"
                  icon="el-icon-time"
                  round
                  @click="updateArrivals(emp)"
                >
                  {{ emp.arrival_time }}
                </el-button>
              </td>
              <!-- <td v-if="columns.arrival_time.show">
                <el-button
                  type="success"
                  size="mini"
                  icon="el-icon-time"
                  round
                  @click="updateArrivals(emp)"
                >
                  {{ emp.arrival_time }}
                </el-button>
              </td> -->
              <td v-if="columns.total_spent_time.show">
                {{ emp.total_spent_time }}
              </td>
              <td v-if="columns.expected_employee.show">
                {{ emp.expected_employee }}
              </td>
              <td v-if="columns.factual_employee.show">
                {{ emp.factual_employee }}
              </td>
              <td v-if="columns.left_employee.show">
                {{ emp.left_employee }}
              </td>
              <td v-if="columns.district.show">
                {{ emp.district }}
              </td>
              <td v-if="columns.created_at.show">
                {{ emp.created_at }}
              </td>
              <td v-if="columns.updated_at.show">
                {{ emp.updated_at }}
              </td>
              <td v-if="columns.settings.show" class="settings-td">
                <crm-settings
                  :name="$options.name"
                  :model="emp"
                  :actions="actions"
                  :permissionShow="'employee_kpps.update'"
                  :permissionDestroy="'employee_kpps.destroy'"
                  @edit="edit"
                  @delete="destroy"
                ></crm-settings>
              </td>
            </tr>
          </transition-group>
        </table>
        <div class="my___pagination">
          <crm-pagination
            @c-change="updatePagination"
            :class="mode ? 'pagination__day' : 'pagination__night'"
            :pagination="pagination"
          ></crm-pagination>
        </div>
      </div>

      <div class="app-modal app-modal__full">
        <el-drawer
          :with-header="false"
          :visible.sync="drawerCreate"
          size="70%"
          ref="drawerCreate"
          @opened="drawerOpened('drawerCreateChild')"
          @closed="drawerClosed('drawerCreateChild')"
        >
          <crm-create
            ref="drawerCreateChild"
            drawer="drawerCreate"
          ></crm-create>
        </el-drawer>

        <el-drawer
          :with-header="false"
          :visible.sync="drawerUpdate"
          size="70%"
          ref="drawerUpdate"
          @opened="drawerOpened('drawerUpdateChild')"
          @closed="drawerClosed('drawerUpdateChild')"
        >
          <crm-update
            :selectedItem="selectedItem"
            ref="drawerUpdateChild"
            drawer="drawerUpdate"
          ></crm-update>
        </el-drawer>
      </div>
      <el-dialog
        title="Время отправления"
        :visible.sync="openDialog"
        width="30%"
        center
        @open="elDialogOpened()"
        :class="mode ? 'dialog__myday' : 'dialog__mynight'"
      >
        <el-form ref="form" status-icon :model="form" :rules="arrivalRules">
          <span>
            <span
              :class="mode ? 'text__day2' : 'text__night2'"
              class="input--label d-block mb-2"
            >
              {{ $t("message.arrival_date") }}
            </span>
            <el-form-item prop="arrival_date" size="medium">
              <crm-date-picker
                :placeholder="$t('message.arrival_date')"
                :date="form.arrival_date"
                v-model="form.arrival_date"
                style="width: 100%"
                :class="mode ? 'input__day' : 'input__night'"
              ></crm-date-picker>
            </el-form-item>
          </span>
          <span>
            <span
              :class="mode ? 'text__day2' : 'text__night2'"
              class="input--label d-block mb-2"
            >
              {{ $t("message.arrival_time") }}
            </span>
            <el-form-item prop="arrival_time" size="medium">
              <el-time-picker
                :placeholder="$t('message.arrival_time')"
                v-model="form.arrival_time"
                value-format="HH:mm:ss"
                format="HH:mm:ss"
                style="width: 100%"
                size="mini"
                :class="mode ? 'input__day' : 'input__night'"
              ></el-time-picker>
            </el-form-item>
          </span>
          <span v-if="selected.type == 'entrance'">
            <span
              :class="mode ? 'text__day2' : 'text__night2'"
              class="input--label d-block mb-2"
            >
              {{ columns.factual_employee.title }}
            </span>
            <el-form-item prop="factual_employee" size="medium">
              <crm-input
                :placeholder="columns.factual_employee.title"
                v-model="form.factual_employee"
                :inputValue="form.factual_employee"
                size="mini"
                type="number"
                :class="mode ? 'input__day' : 'input__night'"
              ></crm-input>
            </el-form-item>
          </span>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="updateArrivalTimes()">
            {{ $t("message.save") }}
          </el-button>
          <el-button type="warning" @click="closeElDialog()">
            {{ $t("message.close") }}
          </el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import selectStaff from "@/components/filters/inventory/select-staff";
import selectGuestCarModel from "@/components/filters/inventory/select-guest-car-model";
import list from "@/utils/mixins/list";
import { i18n } from "@/utils/i18n";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  mixins: [list],
  name: "OperationController",
  components: {
    CrmCreate,
    CrmUpdate,
    selectStaff,
    selectGuestCarModel
  },
  data() {
    return {
      drawer: false,
      openDialog: false,
      currentPage2: 5,
      checkList: [],
      types: [
        {
          label: i18n.t("message.entrance"),
          value: "entrance"
        },
        {
          label: i18n.t("message.exit"),
          value: "exit"
        }
      ],
      form: {
        arrival_date: null,
        arrival_time: null,
        factual_employee: null
      },
      selected: {},
      loadingButton: false,
      arrivalRules: {
        arrival_date: [
          {
            required: true,
            message: "Пожалуйста, введите название ",
            trigger: "change"
          }
        ],
        arrival_time: [
          {
            required: true,
            message: "Пожалуйста, введите название ",
            trigger: "change"
          }
        ],
        factual_employee: [
          {
            required: true,
            message: "Пожалуйста, введите название ",
            trigger: "change"
          }
        ]
      }
    };
  },
  watch: {
    "form.arrival_date": {
      handler: function() {},
      immediate: true,
      deep: true
    },
    "form.arrival_time": {
      handler: function() {},
      immediate: true,
      deep: true
    },
    "form.factual_employee": {
      handler: function() {},
      immediate: true,
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      list: "employeeKpp/list",
      columns: "employeeKpp/columns",
      pagination: "employeeKpp/pagination",
      statues: "employeeKpp/statues",
      filter: "employeeKpp/filter",
      sort: "employeeKpp/sort",
      mode: "MODE"
    }),
    actions: function() {
      return ["delete", "edit"];
    }
  },
  methods: {
    ...mapActions({
      updateList: "employeeKpp/index",
      setPagination: "employeeKpp/setPagination",
      updateSort: "employeeKpp/updateSort",
      updateFilter: "employeeKpp/updateFilter",
      updateColumn: "employeeKpp/updateColumn",
      updatePagination: "employeeKpp/updatePagination",
      show: "employeeKpp/show",
      empty: "employeeKpp/empty",
      delete: "employeeKpp/destroy",
      refreshData: "employeeKpp/refreshData",
      updateDates: "employeeKpp/updateArrivalDateTimes"
    }),
    elDialogOpened() {
      if (
        this.selected.arrival_date == null &&
        this.selected.arrival_time == null
      ) {
        this.form.arrival_date = moment().format("DD.MM.YYYY");
        this.form.arrival_time = moment().format("HH:mm:ss");
      } else {
        this.form.arrival_date = this.selected.arrival_date;
        this.form.arrival_time = this.selected.arrival_time;
      }
      this.form.factual_employee = this.selected.factual_employee;
    },
    updateArrivals(emp) {
      this.selected = emp;
      this.form.id = emp.id;
      this.openDialog = true;
    },
    updateArrivalTimes() {
      this.$refs["form"].validate(valid => {
        if (valid && !this.loadingButton) {
          this.loadingButton = true;
          this.updateDates(this.form)
            .then(res => {
              this.$alert(res);
              if (res.status == 201) {
                this.closeElDialog();
              }
              this.fetchData();
              this.afterFetchData();
              this.loadingButton = false;
            })
            .catch(err => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
    closeElDialog() {
      this.openDialog = false;
      this.form = JSON.parse(
        JSON.stringify({
          arrival_date: null,
          arrival_time: null,
          factual_employee: null
        })
      );
    },
    destroy(model) {
      this.delete(model.id)
        .then(res => {
          this.$alert(res);
          this.fetchData();
        })
        .catch(err => {
          this.$alert(err);
        });
    },
    controlExcelData() {
      this.excel_fields = {};
      for (let key in this.columns) {
        if (this.columns.hasOwnProperty(key)) {
          let column = this.columns[key];
          if (column.show && column.column != "settings") {
            switch (column.column) {
              case "type":
                this.excel_fields[column.title] = {
                  callback: item => {
                    if (item.type == "exit") {
                      return i18n.t("message.exit");
                    } else {
                      return i18n.t("message.entrance");
                    }
                  }
                };
                break;
              case "car_number":
                this.excel_fields[column.title] = {
                  callback: item => {
                    return item.transport ? item.transport.number : "";
                  }
                };
                break;
              case "car_model":
                this.excel_fields[column.title] = {
                  callback: item => {
                    return item.transport ? item.transport.model.model : "";
                  }
                };
                break;
              case "car_color":
                this.excel_fields[column.title] = {
                  callback: item => {
                    return item.transport ? item.transport.color : "";
                  }
                };
                break;
              case "left_date_time":
                this.excel_fields[column.title] = {
                  callback: item => {
                    return (
                      (item.left_date ? item.left_date : "") +
                      " " +
                      (item.left_time ? item.left_time : "")
                    );
                  }
                };
                break;
              case "arrival_date_time":
                this.excel_fields[column.title] = {
                  callback: item => {
                    return (
                      (item.arrival_date ? item.arrival_date : "") +
                      " " +
                      (item.arrival_time ? item.arrival_time : "")
                    );
                  }
                };
                break;
              case "district":
                this.excel_fields[column.title] = {
                  field: "district",
                  callback: district => {
                    return district;
                  }
                };
                break;
              default:
                this.excel_fields[column.title] = column.column;
                break;
            }
          }
        }
      }
    }
  }
};
</script>
